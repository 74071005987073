.process-container{    
    grid-template-rows: 1fr 15rem;

    .client-container{
        align-items: center;
        img{
            height: 10rem;
            width: 10rem;
        }
        p{
            position: absolute;
            font-size: 2rem;
            font-weight: semibold;
            letter-spacing: 0.5rem;
            writing-mode: vertical-rl;
        }
    }
}
.infinite-animation{
    div{
        display: flex;
        position: relative;
        padding: 0 5rem;
        border: 1px solid white;
        white-space: nowrap;
        
    }
}