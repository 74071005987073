.carousel-container{
    display: flex;
    justify-content: flex-end;
    position: relative;
    background-color: white;
    padding: 2rem 0.6rem 0;
    max-width: 75rem;
    .arrow{
        position: absolute;
        cursor: pointer;
        &:hover{
            transform: scale(1.5);
            transition: transform 400ms;
        }
        &.down{
            background-color: gray;
            color: white;
            left: 0;
            top: 10.5rem;
            transform: translateX(-50%);
        }
        &.up{
            background-color: gray;
            color: white;
            left: 0;
            top:8rem;
            transform: translateX(-50%);
        }
        &.left{
            top: 50%;
        }
        &.right{
            top:50%;
            right: 0;
        }
    }
    .carousel-service-title{
        writing-mode: vertical-lr;
        font-size: 2rem;
        margin-left: 1rem;
        p{
            text-transform: uppercase;
        }
    }
    .carousel-content-container{
        display: flex;
        width: 100%;
        flex-direction: column;
        position: relative;
        z-index: 10;
        .carousel-image-container{
            width: 100%;
            position: relative;
            overflow: hidden;
            height: 14rem;
            img{
                width: 100%;
                object-fit: contain;
                opacity: 0;
                top: 0;
                left: 0;
                position: absolute;
                transition: opacity 500ms ease-out;
                &.active{
                    opacity: 1;
                    transition: opacity 500ms ease-in;
                }
            }
            @media all and (min-width: 340px){
                height: 15rem;
            }
            @media all and (min-width: 450px){
                height: 20rem;
            }
            @media all and (min-width: 5300px){
                height: 21rem;
            }
            @media all and (min-width: 640px){
                height: 22.5rem;
            }
            @media all and (min-width: 1290px) {
                height: 25rem;
            }
            @media all and (min-width: 1536px ){
                height: 28rem;
            }
        }
        .carousel-caption-container{
            margin: 0.8rem 0;
            height: 9rem;
            overflow: hidden;
            text-overflow: ellipsis; 
            @media (max-width: 640px) {
                padding: 0.5rem;
            }
            @media (min-width: 640px) {
                min-width: 8rem;
            }
        }

        @media all and (min-width: 1024px) {
                width: 90%;                        
        }
        
    }
    @media (min-width: 1024px) {
        width: 80%;
    }
    @media (min-width: 1290px) {
        width: 70%;
    }
    @media (min-width: 1536px) {
        width: 65%;
    }
    @media (max-width: 435px) {
        padding: 0;
    }
}