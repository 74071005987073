@import '/node_modules/normalize.css/normalize.css';

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */ 
}
*::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
html{
    min-height: 400px;
}
h1{
    font-family: Titillium Web,sans-serif;
    font-size: xx-large;
    color: whitesmoke;
}
h2{
    font-family: Titillium Web,sans-serif;
    font-size: x-large;
    color: whitesmoke;
    letter-spacing: 0.35rem;
}
h3{
    font-family: Titillium Web,sans-serif;
    font-size: large;
    color: whitesmoke;
    letter-spacing: 0.15rem;
}
p{
    color: whitesmoke;
}